<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd"
          ></c-plant>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="휴게시설 목록"
      :columns="gridColumns"
      :data="grid.data"
      :usePaging="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <q-btn v-if="editable" class="custom-btn" label="QR코드(위생점검용)" size="md" icon="qr_code_scanner" color="black">
            <q-popup-proxy>
              <div style="position: sticky;top: 0;left: 0;right: 0;z-index: 99;">
                <q-btn size="sm" icon="print" color="grey" class="full-width" @click.prevent="print" />
              </div>
              <span action="#" id="printJS-form">
                <div class="row" style="width: 800px;">
                  <div v-for="(rest, idx) in grid.data" :key="idx" class="col-3"> 
                    <q-card flat bordered style="height:220px;">
                      <q-card-section class="bg-primary text-white" style="padding: 0;text-align: center;">
                        <div style="font-size: 13px;font-weight: 600;">{{rest.locationName}}<br/>{{rest.restFacilitiesName}}<br/>{{rest.deptName}}</div>
                      </q-card-section>
                      <q-separator />
                      <q-card-section horizontal>
                        <q-card-section style="padding: 0;margin: auto;">
                          <vue-qrcode
                            :value="serverName + '/sop/rtf/his/hygieneInspection?sopRestFacilitiesId=' + rest.sopRestFacilitiesId" 
                            :options="{ width: 150 }"
                            class="canvas"
                          >
                          </vue-qrcode>
                        </q-card-section>
                      </q-card-section>
                    </q-card>
                  </div>
                </div>
              </span>
            </q-popup-proxy>
          </q-btn>
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customBottomRow>
        <q-tr 
          v-if="grid.data && grid.data.length > 0" 
          no-hover
          class="table-bottom-fixed">
          <q-td
            v-for="(col, idx) in gridColumns"
            :key="idx"
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span v-if="col.sum">
              {{ $_.sumBy(grid.data, col.name) }}
            </span>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import printJs from 'print-js'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'restFacilities',
  components: {
    VueQrcode
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      serverName: '',
      fixtureses: [],
      editable: true,
      listUrl: '',
      fixturesListUrl: '',
      popupOptions: {
        isFull: false,
        width: '60%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridColumns() {
      let cols = [
        {
          name: 'plantName',
          field: 'plantName',
          label: '사업장',
          align: 'center',
          sortable: true,
          style: 'width: 100px',
        },
        {
          name: 'locationName',
          field: 'locationName',
          label: '위치',
          align: 'center',
          sortable: true,
          style: 'width: 150px',
        },
        {
          name: 'restFacilitiesName',
          field: 'restFacilitiesName',
          label: '구분',
          style: 'width:300px',
          align: "left",
          sortable: true,
          type: 'link'
        },
        {
          name: 'deptName',
          field: 'deptName',
          label: '반',
          style: 'width:130px',
          align: "center",
          sortable: true,
        },
        {
          name: 'restFacilitiesCount',
          field: 'restFacilitiesCount',
          label: '휴게시설 개수',
          style: 'width:80px',
          align: "center",
          sortable: true,
          sum: true,
        },
      ];
      if (this.fixtureses && this.fixtureses.length > 0) {
        this.$_.forEach(this.fixtureses, fixtures => {
          cols.push({
            name: fixtures.sopRestFacilitiesFixturesId,
            field: fixtures.sopRestFacilitiesFixturesId,
            label: fixtures.fixturesName,
            style: 'width:70px',
            align: "center",
            sortable: true,
            sum: true,
          })
        })
      }
      cols.push({
        name: 'useFlag',
        field: 'useFlag',
        label: '사용여부',
        style: 'width:80px',
        align: "center",
        sortable: true,
      })
      return cols;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rtf.restFacilities.list.url
      this.fixturesListUrl = selectConfig.sop.rtf.restFacilitiesFixtures.list.url
      // code setting
      this.serverName = this.$store.getters.serverUrl
      this.getFixtureses();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getFixtureses() {
      this.$http.url = this.fixturesListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.fixtureses = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '휴게시설 상세';
      this.popupOptions.param = {
        sopRestFacilitiesId: row ? row.sopRestFacilitiesId : '',
      };
      this.popupOptions.target = () => import(`${'./restFacilitiesDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '15px',
        targetStyles: ['*'],
      })
    },
  }
};
</script>
